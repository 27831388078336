import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components'
import { Seo } from '../components/base'
import { LayoutBackground } from '../components/shared'
import { PressPermitForm } from '../components/forms/PressPermitForm'
import { getPageBuilderComponents } from '../components/base/PageBuilderComponents'

const PressPermitPage = ({ data, pageContext }) => {
  if (!data || Object.keys(data.pressPermitPage.nodes).length === 0) {
    return null
  }
  const {
    title,
    subtitle,
    desc,
    subdesc,
    sendMessage,
    errorMessage,
    pressPermitForm,
    pageBuilder
  } = data.pressPermitPage.nodes[0]
  const languages = { pageContext }

  const layoutBackgroundHeaderComponent = (
    <>
      <div className="mx-auto max-w-6xl mb-14 md:mb-24">
        <div className="px-4">
          <h1 className="px-4 md:px-0 mb-5 md:mb-11 text-center">{title}</h1>
          <p className="px-4 md:px-0">{desc}</p>
        </div>
      </div>
      <div className="mx-auto max-w-6xl mb-14 md:mb-24">
        <div className="px-4">
          <h3 className="mb-5 md:mb-11 text-center">{subtitle}</h3>
          <p className="px-4 md:px-0">{subdesc}</p>
        </div>
      </div>
    </>
  )

  return (
    <Layout standardPaddingTopFromNavbarToMain translations={languages}>
      <Seo title={title} description={desc} />
      <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
        <section className="md:px-10 lg:px-0 max-w-6xl mx-auto">
          <PressPermitForm
            formData={pressPermitForm}
            sendMessage={sendMessage}
            errorMessage={errorMessage}
          />
        </section>
        {pageBuilder.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {getPageBuilderComponents(item, false, { ...data })}
            </React.Fragment>
          )
        })}
      </LayoutBackground>
    </Layout>
  )
}

export default PressPermitPage

export const pageQuery = graphql`
  query ($language: String! = "de_de") {
    pressPermitPage: allSanityPressPermitPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        subtitle
        desc
        subdesc
        headline
        errorMessage
        sendMessage
        pageBuilder {
          ...BlockTextComponentQuery
        }
        pressPermitForm {
          emailAddress {
            name
            placeholder
            required
            type
            initialValue
          }
          liaison {
            name
            placeholder
            required
            type
            initialValue
          }
          city {
            name
            placeholder
            required
            type
            initialValue
          }
          media {
            name
            placeholder
            required
            type
            initialValue
          }
          airdate {
            name
            placeholder
            required
            type
            initialValue
          }
          availability {
            name
            placeholder
            required
            initialValue
          }
          editorial {
            name
            placeholder
            required
            type
            initialValue
          }
          topic {
            name
            placeholder
            required
            type
            initialValue
          }
          phoneNumber {
            name
            placeholder
            required
            type
            initialValue
          }
          preferredLocation {
            name
            placeholder
            required
            initialValue
          }
          privacy {
            name
            _rawLabel
            required
            initialValue
          }
        }
      }
    }
    markets: allSanityMarket(filter: { status: { in: ["9"] } }) {
      nodes {
        ...MarketQuery
      }
    }
  }
`
