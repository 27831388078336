import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useField, useFormikContext } from 'formik'
import { BioInputLabel } from './BioInputLabel'
import { useSpring } from '@react-spring/web'
import classNames from 'classnames'
import { CrossMarkIcon } from '../icons/marks/CrossMarkIcon'
import * as styles from './../../../css/atom/bioDatePicker.module.scss'
import de from 'date-fns/locale/de'

registerLocale('de', de)

export const BioDatePicker = ({ required, placeholder, name, ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  const label = required ? `${placeholder}*` : placeholder

  const fieldLabelStyles = useSpring({
    from: {
      y: 0,
      fontSize: '1.8rem'
    },
    to: {
      y: field.value ? -32 : 0,
      fontSize: field.value ? '1.4rem' : '1.8rem',
      fontStyle: field.value ? 'normal' : 'italic'
    }
  })

  const handleOnClick = e => {
    e.stopPropagation()
    e.target.parentElement.querySelector('input').focus()
    e.target.parentElement.querySelector('.react-datepicker_trigger')
  }

  return (
    <div className={classNames(props.css, styles.bioDatePicker)}>
      <BioInputLabel
        styles={fieldLabelStyles}
        label={label}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error}
        name={props.name}
      />

      <DatePicker
        showIcon={!field.value}
        id={name}
        icon={
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleOnClick}>
            <g id="calendar" clipPath="url(#clip0_1798_17335)">
              <g id="vector">
                <path
                  d="M7.79102 8.87492C7.79102 9.26612 7.47389 9.58325 7.08269 9.58325C6.69148 9.58325 6.37435 9.26612 6.37435 8.87492C6.37435 8.48372 6.69148 8.16659 7.08269 8.16659C7.47389 8.16659 7.79102 8.48372 7.79102 8.87492Z"
                  fill="#2E0F08"
                />
                <path
                  d="M9.93913 9.58325C10.3303 9.58325 10.6475 9.26612 10.6475 8.87492C10.6475 8.48372 10.3303 8.16659 9.93913 8.16659C9.54793 8.16659 9.2308 8.48372 9.2308 8.87492C9.2308 9.26612 9.54793 9.58325 9.93913 9.58325Z"
                  fill="#2E0F08"
                />
                <path
                  d="M13.5055 8.87492C13.5055 9.26612 13.1884 9.58325 12.7972 9.58325C12.406 9.58325 12.0889 9.26612 12.0889 8.87492C12.0889 8.48372 12.406 8.16659 12.7972 8.16659C13.1884 8.16659 13.5055 8.48372 13.5055 8.87492Z"
                  fill="#2E0F08"
                />
                <path
                  d="M6.66569 12.4405C7.0569 12.4405 7.37403 12.1234 7.37403 11.7322C7.37403 11.341 7.0569 11.0238 6.66569 11.0238C6.27449 11.0238 5.95736 11.341 5.95736 11.7322C5.95736 12.1234 6.27449 12.4405 6.66569 12.4405Z"
                  fill="#2E0F08"
                />
                <path
                  d="M10.2305 11.7322C10.2305 12.1234 9.91334 12.4405 9.52214 12.4405C9.13094 12.4405 8.81381 12.1234 8.81381 11.7322C8.81381 11.341 9.13094 11.0238 9.52214 11.0238C9.91334 11.0238 10.2305 11.341 10.2305 11.7322Z"
                  fill="#2E0F08"
                />
                <path
                  d="M12.3802 12.4405C12.7714 12.4405 13.0885 12.1234 13.0885 11.7322C13.0885 11.341 12.7714 11.0238 12.3802 11.0238C11.989 11.0238 11.6719 11.341 11.6719 11.7322C11.6719 12.1234 11.989 12.4405 12.3802 12.4405Z"
                  fill="#2E0F08"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.37565 3.33333C7.37565 2.94213 7.05852 2.625 6.66732 2.625C6.27612 2.625 5.95898 2.94213 5.95898 3.33333V4.29167H5.83402C4.52234 4.29167 3.45899 5.35499 3.45899 6.66667V9.56469C3.4542 11.0405 2.89919 12.2628 2.3261 13.1315C2.03938 13.5661 1.75286 13.9047 1.54007 14.1328C1.43392 14.2466 1.34685 14.332 1.28829 14.3874C1.25903 14.415 1.23697 14.4351 1.22328 14.4473L1.20825 14.4606L1.2073 14.4614C0.982039 14.6538 0.900263 14.9662 1.00249 15.2443C1.10493 15.5231 1.37038 15.7083 1.66735 15.7083H3.45899V15.8333C3.45899 17.145 4.52231 18.2083 5.83399 18.2083H14.1673C15.479 18.2083 16.5423 17.145 16.5423 15.8333V9.57535L16.5424 9.54546V6.66492C16.5424 5.35227 15.4781 4.29167 14.1674 4.29167H14.0423V3.33333C14.0423 2.94213 13.7252 2.625 13.334 2.625C12.9428 2.625 12.6257 2.94213 12.6257 3.33333V4.29167H7.37565V3.33333ZM15.1257 14.5857C14.7483 15.0677 14.3767 15.357 14.1271 15.5141C13.8856 15.6662 13.6295 15.7083 13.4218 15.7083H4.87565V15.8333C4.87565 16.3626 5.30472 16.7917 5.83399 16.7917H14.1673C14.6966 16.7917 15.1257 16.3626 15.1257 15.8333V14.5857ZM13 5.70833H6.5C6.93783 5.70833 7.05852 5.70833 6.66732 5.70833C6.27612 5.70833 6.37435 5.70833 6.1875 5.70833H5.83402C5.30433 5.70833 4.87569 6.13706 4.87569 6.66592V9.54546C4.87569 11.3912 4.18303 12.8893 3.50861 13.9116C3.41988 14.0461 3.33114 14.1729 3.24387 14.2917H13.4087C13.7719 14.0484 15.1257 12.9341 15.1257 9.54546V6.66492C15.1257 6.13661 14.6976 5.70833 14.1674 5.70833H13.7031C13.2546 5.70833 13.6256 5.70833 13.2344 5.70833C12.8432 5.70833 13.334 5.70833 13 5.70833Z"
                  fill="#2E0F08"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_1798_17335">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.000488281)"
                />
              </clipPath>
            </defs>
          </svg>
        }
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={date => {
          setFieldValue(field.name, date)
        }}
        wrapperClassName={styles.datePicker}
        className={classNames(styles.__input)}
        locale="de"
      />
      {field.value && (
        <div
          className={classNames(styles.__cross)}
          onClick={() => {
            helpers.setValue('')
            helpers.setTouched(false)
          }}>
          {!props.css && <CrossMarkIcon size="md" />}
        </div>
      )}

      {meta.touched && meta.error && <div>{meta.error}</div>}
    </div>
  )
}
